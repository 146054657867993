export const notificationListData = [
    {
        id: 'b06ca3f5-8fb0-4979-a016-30dfe63e8fd6',
        target: 'Jean Bowman',
        description: 'invited you to new project.',
        date: '4 minutes ago',
        image: 'thumb-8.jpg',
        type: 0,
        location: '',
        locationLabel: '',
        status: '',
        readed: false
    },
    {
        id: '2152cd09-413a-44be-9d5a-b2b820c6a661',
        target: 'Vickie Kim',
        description: 'comment in your ticket.',
        date: '20 minutes ago',
        image: '',
        type: 0,
        location: '',
        locationLabel: '',
        status: '',
        readed: false
    },
    {
        id: 'f644235d-dffc-4f17-883f-1ada117ff2c9',
        target: '',
        description: 'Please submit your daily report.',
        date: '1 hour ago',
        image: '',
        type: 1,
        location: '',
        locationLabel: '',
        status: '',
        readed: false
    },
    {
        id: '8ca04d2c-0262-417b-8a3d-4ade49939059',
        target: '',
        description: 'Your request was rejected',
        date: '2 days ago',
        image: '',
        type: 2,
        location: '',
        locationLabel: '',
        status: 'failed',
        readed: true
    },
    {
        id: 'e55adc24-1803-4ffd-b653-09be273f8df5',
        target: 'Jennifer Ruiz',
        description: 'mentioned your in comment.',
        date: '2 days ago',
        image: 'thumb-4.jpg',
        type: 0,
        location: '',
        locationLabel: '',
        status: '',
        readed: true
    },
    {
        id: '8dd23dfd-a79b-40ad-b4e9-7e70a148d5b6',
        target: '',
        description: 'Your request has been approved.',
        date: '4 minutes ago',
        image: '4 days ago',
        type: 2,
        location: '',
        locationLabel: '',
        status: 'succeed',
        readed: true
    },
]

export const searchQueryPoolData = [
    {
        title: 'Project Dashboard',
        url: '/app/project/dashboard',
        icon: 'project',
        category: 'Project',
        categoryTitle: '',
    },
    {
        title: 'Project List',
        url: '/app/project/project-list',
        icon: 'project',
        category: 'Project',
        categoryTitle: '',
    },
    {
        title: 'Scrumboard',
        url: '/app/project/scrum-board',
        icon: 'project',
        category: 'Project',
        categoryTitle: '',
    },
    {
        title: 'Issue',
        url: '/app/project/issue',
        icon: 'project',
        category: 'Project',
        categoryTitle: '',
    },
    {
        title: 'CRM Dashboard',
        url: '/app/crm/dashboard',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: '',
    },
    {
        title: 'Calendar',
        url: '/app/crm/calendar',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: '',
    },
    {
        title: 'Customers',
        url: '/app/crm/customers',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: '',
    },
    {
        title: 'Customer Details',
        url: '/app/crm/customer-details?id=8',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: '',
    },
    {
        title: 'Mail',
        url: '/app/crm/mail',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: '',
    },
    {
        title: 'Sales Dashboard',
        url: '/app/sales/dashboard',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: '',
    },
    {
        title: 'Product List',
        url: '/app/sales/product-list',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: '',
    },
    {
        title: 'Product Edit',
        url: '/app/sales/product-edit/12',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: '',
    },
    {
        title: 'New Product',
        url: '/app/sales/product-new',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: '',
    },
    {
        title: 'Order List',
        url: '/app/sales/order-list',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: '',
    },
    {
        title: 'Order Details',
        url: '/app/sales/order-details/95954',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: '',
    },
    {
        title: 'Crypto Dashboard',
        url: '/app/crypto/dashboard',
        icon: 'crypto',
        category: 'Crypto',
        categoryTitle: '',
    },
    {
        title: 'Portfolio',
        url: '/app/crypto/portfolio',
        icon: 'crypto',
        category: 'Crypto',
        categoryTitle: '',
    },
    {
        title: 'Market',
        url: '/app/crypto/market',
        icon: 'crypto',
        category: 'Crypto',
        categoryTitle: '',
    },
    {
        title: 'Wallets',
        url: '/app/crypto/wallets',
        icon: 'crypto',
        category: 'Crypto',
        categoryTitle: '',
    },
    {
        title: 'Help Center',
        url: '/app/knowledge-base/help-center',
        icon: 'knowledgeBase',
        category: 'Knowledge Base',
        categoryTitle: '',
    },
    {
        title: 'Article',
        url: '/app/knowledge-base/article?id=rZjCbSyae5',
        icon: 'knowledgeBase',
        category: 'Knowledge Base',
        categoryTitle: '',
    },
    {
        title: 'Manage Articles',
        url: '/app/knowledge-base/manage-articles',
        icon: 'knowledgeBase',
        category: 'Knowledge Base',
        categoryTitle: '',
    },
    {
        title: 'Edit Article',
        url: '/app/knowledge-base/edit-article?id=rZjCbSyae5&categoryLabel=Survey&categoryValue=survey',
        icon: 'knowledgeBase',
        category: 'Knowledge Base',
        categoryTitle: '',
    },
    {
        title: 'Settings',
        url: '/app/account/settings/profile',
        icon: 'account',
        category: 'Account',
        categoryTitle: '',
    },
    {
        title: 'Invoice',
        url: '/app/account/invoice/36223',
        icon: 'account',
        category: 'Account',
        categoryTitle: '',
    },
    {
        title: 'Activity Log',
        url: '/app/account/activity-log',
        icon: 'account',
        category: 'Account',
        categoryTitle: '',
    },
    {
        title: 'KYC Form',
        url: '/app/account/kyc-form',
        icon: 'account',
        category: 'Account',
        categoryTitle: '',
    },
    {
        title: 'Button',
        url: '/ui-components/button',
        icon: 'common',
        category: 'Common',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Grid',
        url: '/ui-components/grid',
        icon: 'common',
        category: 'Common',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Typography',
        url: '/ui-components/typography',
        icon: 'common',
        category: 'Common',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Icons',
        url: '/ui-components/icons',
        icon: 'common',
        category: 'Common',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Alert',
        url: '/ui-components/alert',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Dialog',
        url: '/ui-components/dialog',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Drawer',
        url: '/ui-components/drawer',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Progress',
        url: '/ui-components/progress',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Skeleton',
        url: '/ui-components/skeleton',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Spinner',
        url: '/ui-components/spinner',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Toast',
        url: '/ui-components/toast',
        icon: 'feedback',
        category: 'Feedback',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Avatar',
        url: '/ui-components/avatar',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Badge',
        url: '/ui-components/badge',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Cards',
        url: '/ui-components/cards',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Calendar',
        url: '/ui-components/calendar',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Table',
        url: '/ui-components/table',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Tag',
        url: '/ui-components/tag',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Timeline',
        url: '/ui-components/timeline',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Tooltip',
        url: '/ui-components/tooltip',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Checkbox',
        url: '/ui-components/checkbox',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Date picker',
        url: '/ui-components/date-picker',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Form control',
        url: '/ui-components/form-control',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Input',
        url: '/ui-components/input',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Input Group',
        url: '/ui-components/input-group',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Radio',
        url: '/ui-components/radio',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Segment',
        url: '/ui-components/segment',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Select',
        url: '/ui-components/select',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Switcher',
        url: '/ui-components/switcher',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'TimeInput',
        url: '/ui-components/time-input',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Upload',
        url: '/ui-components/upload',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Dropdown',
        url: '/ui-components/dropdown',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Menu',
        url: '/ui-components/menu',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Pagination',
        url: '/ui-components/pagination',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Steps',
        url: '/ui-components/steps',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Tabs',
        url: '/ui-components/tabs',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Charts',
        url: '/ui-components/charts',
        icon: 'graph',
        category: 'Graph',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Maps',
        url: '/ui-components/maps',
        icon: 'graph',
        category: 'Graph',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Welcome',
        url: '/pages/welcome',
        icon: 'pages',
        category: 'Pages',
        categoryTitle: 'Pages',
    },
    {
        title: 'Access Denied',
        url: '/access-denied',
        icon: 'pages',
        category: 'Pages',
        categoryTitle: 'Pages',
    },
    {
        title: 'Sign In Simple',
        url: '/auth/sign-in-simple',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Sign In Side',
        url: '/auth/sign-in-side',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Sign In Cover',
        url: '/auth/sign-in-cover',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Sign Up Simple',
        url: '/auth/sign-up-simple',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Sign Up Side',
        url: '/auth/sign-up-side',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Sign Up Cover',
        url: '/auth/sign-up-cover',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Forgot Password Simple',
        url: '/auth/forgot-password-simple',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Forgot Password Side',
        url: '/auth/forgot-password-side',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Forgot Password Cover',
        url: '/auth/forgot-password-cover',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Reset Password Simple',
        url: '/auth/reset-password-simple',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Reset Password Side',
        url: '/auth/reset-password-side',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Reset Password Cover',
        url: '/auth/reset-password-cover',
        icon: 'authentication',
        category: 'Authentication',
        categoryTitle: 'Auth',
    },
    {
        title: 'Documentation',
        url: '/docs/documentation/introduction',
        icon: 'documentation',
        category: 'Docs',
        categoryTitle: 'Docs',
    },
    {
        title: 'Shared Component',
        url: '/docs/shared-component-doc/action-link',
        icon: 'sharedComponentDoc',
        category: 'Docs',
        categoryTitle: 'Docs',
    },
    {
        title: 'Utilities',
        url: '/docs/utils-doc/use-auth',
        icon: 'utilsDoc',
        category: 'Docs',
        categoryTitle: 'Docs',
    },
    {
        title: 'Changelog',
        url: '/docs/changelog',
        icon: 'changeLog',
        category: 'Docs',
        categoryTitle: 'Docs',
    },
]
