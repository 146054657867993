const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: ['/app/vendor/dashboard'],
    authenticatedEntryPathSingle: '/app/vendor/dashboard',
    unAuthenticatedEntryPath: '/app/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: true,
    signIn:"/app/sign-in"
}

export default appConfig