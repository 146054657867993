import React, { useEffect,useState } from "react";
import axios from "axios";
import ApiService, { CompanyApiService } from './ApiService'

const baseURL = "https://zinggerr.com/api/UserLogin/signin";

// export async function doPostRequest( values) {
//      (values)
//     let payload = {	
//         			email: 'arpitasaxena555@gmail.com',
//                     password: 'ceb6c970658f31504a901b89dcd3e461',
//                     device_type: 'Web',
//                     device_id: '223.233.77.58' 
//                     // userName: `${values.userName}`,
//                     // password: `${values.password}`
//                 };

//     let res = await axios.post(baseURL, payload);

//     let data = res.data;
//     (data);
// }

// doPostRequest();

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/sign-in',
        method: 'post',
        data
    })
}

export async function companySignInData(){
    return new Promise(async(res, rej) => {
        try {
            let payload = {
                email: 'arpitasaxena555@gmail.com',
                password: 'ceb6c970658f31504a901b89dcd3e461',
                device_type: 'Web',
                device_id: '223.233.77.58'
            };
    
            let rep = await axios.post("https://zinggerr.com/api/UserLogin/signin", payload);
    
            let data = rep.data;
            res(data)
        } catch (error) {
            rej(error)
        }
    })
}

export async function apiCompanySignIn () {
    // ("Api hit data ===========================");
    return CompanyApiService.fetchData({
        url: 'companyLogin',
        method: 'post'
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
