import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, companySignInData } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { setVendorDataFromLoginPage } from "store/vendor/vendorSlice";
import { login, retrieveVendorProfile } from "services/LiveApi";
import jwt_decode from "jwt-decode";
import { Notification, toast } from 'components/ui'

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const retrievedataOnLogin = async (token) => {
    const retrieveData = await retrieveVendorProfile(token);
    dispatch(setVendorDataFromLoginPage(retrieveData.response));
  };

  const signIn = async ({ email, password }) => {
    localStorage.clear();

    try {
      const resp = await login({ email, password });
      const tk = resp.Body;
      localStorage.setItem("token", tk);

      console.log("tokennn",tk)

      let Decoded = jwt_decode(tk);
      if (tk && Decoded.role=="vendor" ) {
        retrievedataOnLogin(tk);

        if (resp.Body && Decoded.role=="vendor") {
          dispatch(onSignInSuccess(tk));
          dispatch(
            setUser({
              avatar: "",
              userName: Decoded.name,
              authority: ["USER", "ADMIN"],
              email: email,
              role: Decoded.role,
              user_id: Decoded.user_id,
              partnerType:Decoded.partnerType,
              isProfileCompleted:Decoded.isProfileCompleted
            })
          );
        }
        if(Decoded.role=="vendor"){const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        }
      }
       
      }else{
        toast.push(
          <Notification title={`incorrect mail or password`} type="danger" />
          , {
            placement: 'top-center'
            
          })
      }
     

    } 
    catch (errors) {
      {

        toast.push(
          <Notification title={`incorrect credentials`} type="danger" />
          , {
            placement: 'top-center'
            
          })
      }
      return {
        
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      localStorage.clear();
      await apiSignOut();
      handleSignOut();
      localStorage.clear();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;

