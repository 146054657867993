import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import vendor from './vendor/vendorSlice'
import auth from './auth'
import base from './base'

const rootReducer = (asyncReducers) => (state, action) => {
    let tokenData = localStorage.getItem('token')
    // ("tokenData :::::::::", tokenData)
    let combinedReducer;
    if (!tokenData) {
        combinedReducer = combineReducers({
            theme,
            auth,
            base,
            vendor,
            ...asyncReducers,
        })

        return combinedReducer(undefined, action)
    } else {
        combinedReducer = combineReducers({
            theme,
            auth,
            base,
            vendor,
            ...asyncReducers,
        })
        return combinedReducer(state, action)
    }
}
  
export default rootReducer
