import Axios from "axios";
import { Notification, toast } from "components/ui";

const BASE_ED_URL = "https://www.ed2100.com/api/";
const BASE_RD_LOCAL_URL = "https://demo-partner.ed2100.com/api2/";

export function register({
  email,
  name,
  password,
  passwordConf,
  mobileNumber,
  role,
  countryCode,
}) {
  let body = {
    email: email,
    name: name,
    password: password,
    passwordConf: passwordConf,
    role: role,
    mobileNumber: mobileNumber,
    countryCode: countryCode,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/register", body, config)
      .then((response) => {
        // ('response ::::::::::', response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function login({ email, password }) {
  let body = {
    email: email,
    password: password,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/login", body, config)
      .then((response) => {
        // ('response ::::::::::', response);
        res(response.data);
      })
      .catch((err) => {
        rej(err);
        toast.push(
          <Notification
            title={`This Email Is not regestered!`}
            type="danger"
          />,
          {
            placement: "top-center",
          }
        );
      });
  });
}

export function forgetPassword(email, password, passwordConf) {
  let body = {
    email: email,
    password: password,
    passwordConf: passwordConf,
  };
  // let config = {
  // 	headers: {
  // 		'Content-Type': 'application/json'
  // 	}
  // };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/forget", body)
      .then((response) => {
        // ('response ::::::::::', response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveVendorProfile(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieveVendorProfile`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function createProfile(tk, values) {
  const tagArray = [];
  if (values.tags && values.tags.length > 0) {
    values.tags.map((tagData) => {
      tagArray.push(tagData.label);
    });
  }
  const address = {
    line1: values.line1,
    // line2: values.line2,
    city: values.city,
    state: values.state,
    countryCode: values.countryCode,
    country: values.country,
    postalCode: values.postalCode,
  };
  let body = {
    firstName: values.firstName,
    lastName: values.lastName,
    partnerType:values.partnerType,
    // email: values.email,
    // role: values.role,
    profileAvatarId: values.profileAvatarId,
    bannerImage: values.bannerImage,
    backgroundColor: values.backgroundColor,
    fontColor: values.fontColor,
    fontHeaderColor: values.fontHeaderColor,
    profilePic: values.profilePic,
    companyPic: values.companyPic,
    description: values.description,
    contactNumber: values.contactNumber,
    brandName: values.brandName,
    companyName: values.companyName,
    gstNumber: values.gstNumber,
    incorporationCertificate: values.incorporationCertificate,
    address: address,
    tags: tagArray,
    organisationSize:values.organisationSize

  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createVendorProfile", body, config)
      .then((response) => {
        // ('response from cvreate profile::::::::::', response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createCoursesForVendor(tk, values) {
  const abc = [];
  const def = [];
  const ghi = [];
  values.subjects.map((val) => {
    abc.push({ name: val.label, subjectThumbnail: val.subjectThumbnail });
  });
  values.tags.map((tags) => {
    def.push(tags.label);
  });
  values.grade.map((grades) => {
    ghi.push(grades.label);
  });
  let body = {
    // courseId: values.courseId,
    types: values.types,
    courseName: values.courseName,
    board: values.board,
    grade: ghi,
    level: values.level,
    courseThumbnail: values.courseThumbnail,
    courseDescription: values.courseDescription,
    tags: def,
    statusColor: "red",
    subjects: abc,
    status: values.status
    // subjects: [
    // 	{
    // 		name: 'physics',
    // 		subjectThumbnail:
    // 			'https://images.pexels.com/photos/3769118/pexels-photo-3769118.jpeg?auto=compress&cs=tinysrgb&w=600'
    // 	},
    // ]
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createCoursesForVendor", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveCoursesForVendorById(tk,courseId) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `user/retrieve-course-detail-with-content/${courseId}`,config
      
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function createCoursesForVendorEdit(tk, values) {
  const abc = [];
  const def = [];
  const ghi = [];
  // values.subjects.map((val) => {
  // 	abc.push(val.label);
  // });
  values.tags.map((tags) => {
    def.push(tags.label);
  });
  values.grade.map((grades) => {
    ghi.push(grades.label);
  });
  let body = {
    courseId: values.courseid,
    types: values.types,
    courseName: values.courseName,
    board: values.board,
    grade: ghi,
    statusColor: "red",
    // subjects: abc,
    level: values.level,
    courseThumbnail: values.courseThumbnail,
    courseDescription: values.courseDescription,
    tags: def,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createCoursesForVendor", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveCourses(courseId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `retrieve-course-by-id/${courseId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveSubjects(courseId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-subject-by-id/${courseId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveChapters(subjectId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-chapter-by-id/${subjectId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}


export function retrieveFilteredChapter(grades , subjects) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `vendor/retrieve-filtered-chapter?grade=${grades}&subject=${subjects}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

// user/retrieve-chapter-by-id/63e10d1312a832282fb0e036


export function retrieveTopics(topicId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-topic-by-id/${topicId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveContents(topicId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-content-by-id/${topicId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function createChapterForCourse(tk, values) {
  let body = {
    chapterName: values.chapterName,
    chapterThumbnail: values.chapterThumbnail,
    chapterDescription: values.chapterDescription,
    chapterSubject: values.chapterSubject,
    practice: values.practice,
    grade: values.grade,
    subjectId: values.subjectId,
    topics: values.topics,
    status:values.status
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createChapterForCourse", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createChapterForCourseEdit(tk, values) {
  let body = {
    chapterName: values.chapterName,
    chapterThumbnail: values.chapterThumbnail,
    chapterDescription: values.chapterDescription,
    chapterSubject: values.chapterSubject,
    practice: values.practice,
    grade: values.grade,
    chapterId: values.chapterId,
    // subjectId: values.subjectId,
    // topics:values.topics
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createChapterForCourse", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createTopicrForCourseEdit(tk, values) {
  let body = {
    topicName: values.topicName,
    topicThumbnail: values.topicThumbnail,
    topicDescription: values.topicDescription,
    topicId: values.topicId,
    // subjectId: values.subjectId,
    // topics:values.topics
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/update-topic", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createSubjectrForCourseEdit(tk, values) {
  let body = {
    name: values.name,
    subjectThumbnail: values.subjectThumbnail,

    subjectId: values.subjectId,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/update-subject", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createContentForChapterEdit(tk, values) {
  let body = {
    contentThumbnail: values.contentThumbnail,
    contentName: values.contentName,
    contentURL: values.contentURL,
    duration: values.duration,
    contentDescription: values.contentDescription,
    contentType: values.contentType,
    contentId: values.contentId,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createContentForChapter", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createContentForChapter(tk, values, dur) {
  let body = {
    contentThumbnail: values.contentThumbnail,
    contentName: values.contentName,
    contentURL: values.contentURL,
    duration: dur,
    contentDescription: values.contentDescription,
    contentType: values.contentType,
    topicId: values.topicId,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/createContentForChapter", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function zinggerrLogin() {
  let body = {
    email: "arpitasaxena555@gmail.com",
    password: "ceb6c970658f31504a901b89dcd3e461",
    device_type: "Web",
    device_id: "223.233.77.58",
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return new Promise((res, rej) => {
    Axios.post("https://zinggerr.com/api/UserLogin/signin", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function viewContent(tk, contentid) {
  let body = {
    contentid: contentid,
    usertype: "NotParent",
    userid: "101",
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      "X-Token": tk,
      "X-Device-Id": "223.233.77.58",
    },
  };
  return new Promise((res, rej) => {
    Axios.post("https://zinggerr.com/api/Content_v1/viewContent", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createMarketCampaign(tk, values) {
  const tagArray = [];
  const targetedAudienceArray = [];
  const locationArray = [];

  values.tag.map((tagLabel) => {
    tagArray.push(tagLabel.label);
  });

  values.targetedAudience.map((targetedAudienceValue) => {
    targetedAudienceArray.push(targetedAudienceValue.label);
  });

  values.location.map((locationValue) => {
    locationArray.push(locationValue.label);
  });

  let body = {
    courseId: values.courseId,
    compaignName: values.compaignName,
    image: values.image,
    targetedAudience: targetedAudienceArray,
    language: values.language,
    location: locationArray,
    contentType: values.contentType,
    tag: tagArray,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL + "user/create-marketing-compaign",
      body,
      config
    )
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveMarketCampaignDataOfVendor(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL + `user/get-all-marketing-data-of-vendor`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function updateStatusForCourse(tk, courseId, status, color) {
  let body = {
    courseId: courseId,
    status: status,
    statusColor: color,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/update-status-of-course", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveDashboardDataForVendor(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/get-dashboard-data`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function createMonetization(tk, values) {
  let body = {
    courseId: values.courseId,
    isComboModel: values.isComboModel,
    isLicensingModel: values.isLicensingModel,
    courseDuration: values.courseDuration,
    courseTrialPeriod: values.courseTrialPeriod,
    coursePrice: values.coursePrice,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL + "vendor/create-monetization-plan",
      body,
      config
    )
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveMonetizationPlansDataOfVendor(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `vendor/get-all-monetization-plans`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveCoursesDataForMonetization(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `vendor/retrieve-courses-for-monitsation-for-new-plan`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrievePsychometricListData(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `vendor/retrieve-all-psychometric-list-of-vendor`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
