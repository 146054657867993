export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-2.jpg',
        userName: 'Rajat Gupta',
        email: 'rajat.gupta@ed2100.com',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin'
    }
]